import React from "react"
import { useSelector } from "react-redux"

import { FrontPageGrid } from "src/layout/styles"
import ProjectThumbnail from "src/components/ProjectThumbnail"
import { sizes } from "./Image"

const ProjectList = () => {
  const projects = useSelector(state => state.projects)

  return (
    <FrontPageGrid>
      {projects.map(project => (
        <ProjectThumbnail
          project={project}
          key={project._id}
          sizes={sizes.frontPageGrid}
        />
      ))}
    </FrontPageGrid>
  )
}
export default ProjectList
