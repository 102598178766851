import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled, { css } from "styled-components"
import { window } from "browser-monads"

import Image, { sizes as presetSizes } from "src/components/Image"
import { ItalicMedium } from "src/layout/styles"
import useProjectData from "src/hooks/useProjectData"
import { media } from "src/layout/mediaQueries"

const ProjectThumbnail = ({
  project,
  prev = "home",
  size = "small",
  sizes,
}) => {
  const { url, alt, slug, hero, artist, title } = useProjectData(project)

  return (
    <StyledLink to={url} state={{ prev }}>
      <motion.div layoutId={`${slug}-${prev}`}>
        <motion.div>
          <Title title={title} artist={artist} size={size} />
          <StyledImage
            image={hero}
            alt={alt}
            size={size}
            sizes={
              window.location.pathname === `/project/${slug}`
                ? presetSizes.fullWidth
                : sizes
            }
          />
        </motion.div>
      </motion.div>
    </StyledLink>
  )
}

const Title = ({ size, artist, title }) => (
  <TitleCard size={size}>
    <div>{artist}</div>
    <div>
      <ItalicMedium>{title}</ItalicMedium>
    </div>
  </TitleCard>
)

const StyledImage = styled(Image)`
  min-height: 10rem;
  ${({ size }) =>
    size === "large" &&
    css`
      height: 75vh;
      width: 100vw;
      max-width: 100vw;
      @media (hover: hover) and (pointer: fine) {
        height: 85vh;
      }
    `}
`

const TitleCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--colour-yellow);
  color: var(--colour-black);
  opacity: 0;
  transition: opacity 0.2s;
  font-family: var(--font-medium);
  font-size: var(--medium-type);
  line-height: 1.4;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ size }) =>
    size === "large" &&
    css`
      top: auto;
      right: var(--small-space);
      left: var(--small-space);
      bottom: var(--small-space);
      ${media.largeMobile`
        right: auto;
        aspect-ratio: 1.775147929;
        width: calc(50% - var(--large-space));
      `}
      ${media.smallTablet`
        width: calc(33% - var(--small-space));
      `}
      ${media.tablet`
        left: var(--large-space);
        bottom: var(--large-space);
        width: calc(33% - var(--large-space));
      `}
      ${media.desktop`
        width: calc(25% - var(--large-space));
      `}
    `}
`

const StyledLink = styled(Link)`
  display: block;
  position: relative;

  &:hover {
    opacity: 1;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover ${TitleCard} {
      opacity: 1;
    }
  }
`

export default ProjectThumbnail
