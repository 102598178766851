import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = ({
  image: asset,
  alt,
  style,
  draggable = false,
  className,
  sizes,
}) => {
  if (sizes && asset?.gatsbyImageData?.images?.fallback?.sizes) {
    asset.gatsbyImageData.images.fallback.sizes = sizes
  }
  const img = getImage(asset?.localFile || asset)
  return img ? (
    <GatsbyImage
      image={img}
      alt={alt}
      style={style}
      draggable={draggable}
      className={className}
    />
  ) : null
}

export const sizes = {
  fullWidth: "100vw",
  frontPageGrid:
    "(max-width: 370px) calc(100vw - (16px*2)), (max-width: 699px) calc((100vw - (16px*3))/2), (max-width: 999px) calc((100vw - (16px*4))/3), (max-width: 1399px) calc((100vw - (25px*4))/3), (max-width: 2800px) calc((100vw - (25px*5))/4), (max-width: 3500px) calc((100vw - (25px*5))/4), (max-width: 4500px) calc((100vw - (25px*5))/4), (max-width: 5500px) calc((100vw - (25px*5))/4), 100vw",
  singleColumn:
    "(max-width: 370px) calc(100vw - (16px*2)), (max-width: 699px) calc(100vw - (16px*2)), (max-width: 999px) calc(100vw - (16px*2)), (max-width: 1399px) calc(100vw - (25px*2)), (max-width: 2000px) calc(100vw - (25px*2)), (max-width: 2999px) calc(100vw - (25px*2)), 100vw",
  twoColumn:
    "(max-width: 370px) calc((100vw - (16px*3))/2), (max-width: 699px) calc((100vw - (16px*3))/2), (max-width: 999px) calc((100vw - (16px*3))/2), (max-width: 1399px) calc((100vw - (25px*3))/2), (max-width: 2000px) calc((100vw - (25px*3))/2), (max-width: 2999px) calc((100vw - (25px*3))/2), 100vw",
  threeColumn:
    "(max-width: 370px) calc((100vw - (16px*4))/3), (max-width: 699px) calc((100vw - (16px*4))/3), (max-width: 999px) calc((100vw - (16px*4))/3), (max-width: 1399px) calc((100vw - (25px*4))/3), (max-width: 2000px) calc((100vw - (25px*4))/3), (max-width: 2999px) calc((100vw - (25px*4))/3), 100vw",
  fourColumn:
    "(max-width: 370px) calc((100vw - (16px*5))/4), (max-width: 699px) calc((100vw - (16px*5))/4), (max-width: 999px) calc((100vw - (16px*5))/4), (max-width: 1399px) calc((100vw - (25px*5))/4), (max-width: 2000px) calc((100vw - (25px*5))/4), (max-width: 2999px) calc((100vw - (25px*5))/4), 100vw",
  relatedProject:
    "(max-width: 370px) 280px, (max-width: 699px) 280px, (max-width: 999px) 312px, (max-width: 1399px) 433px, (max-width: 2000px) 633px, (max-width: 2999px) 966px, 100vw",
}

export default Image
