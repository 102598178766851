import React from "react"
import { useSelector } from "react-redux"

import ProjectThumbnail from "./ProjectThumbnail"
import { sizes } from "./Image"

const FeaturedProject = () => {
  const featuredProject = useSelector(state => state.featuredProject)
  return (
    featuredProject?.slug?.current ? (
    <div>
      <ProjectThumbnail
        project={featuredProject}
        size="large"
        sizes={sizes.fullWidth}
        prev="featured-home"
      />
    </div>) : null
  )
}

export default FeaturedProject
