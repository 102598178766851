import React from "react"
import { Provider } from "react-redux"

import { store } from "../store"
import PunktHeader from "src/components/PunktHeader"
import { AnimateSharedLayout, AnimatePresence } from "framer-motion"

import Home from "src/components/Home"
import About from "src/components/About"
import InitializeState from "src/store/InitializeState"

const Layout = ({ children, location }) => {
  return (
    <Provider store={store}>
      <InitializeState />
      <AnimateSharedLayout type="crossfade">
        <>
          <PunktHeader location={location} />
          <AnimatePresence>{children}</AnimatePresence>
          <Home />
        </>
        <About />
      </AnimateSharedLayout>
    </Provider>
  )
}

export default Layout
