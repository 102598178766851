import { css } from "styled-components"

export const media = {
  mobileOnly: (...args) => css`
    @media screen and (max-width: 370px) {
      ${css(...args)};
    }
  `,
  largeMobile: (...args) => css`
    @media screen and (min-width: 371px) {
      ${css(...args)};
    }
  `,
  smallTablet: (...args) => css`
    @media screen and (min-width: 700px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media screen and (min-width: 1000px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media screen and (min-width: 1400px) {
      ${css(...args)};
    }
  `,
  largeDesktop: (...args) => css`
    @media screen and (min-width: 2000px) {
      ${css(...args)};
    }
  `,
}
