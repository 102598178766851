import styled from "styled-components"
import { media } from "src/layout/mediaQueries"
import { motion } from "framer-motion"

export const FrontPageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--small-space);
  margin: var(--small-space);
  min-height: 100vh;

  ${media.largeMobile`
    grid-template-columns: 1fr 1fr;
  `};

  ${media.smallTablet`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${media.tablet`
    gap: var(--large-space);
    margin: var(--large-space);
  `};

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

export const ProjectPageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--small-space);
  margin: calc(var(--small-space) * 2) var(--small-space);

  ${media.smallTablet`
    grid-template-columns: repeat(12, 1fr);

    .single-column {
      grid-column-start: 1;
      grid-column-end: span 12;
      &.portrait {
        grid-column-start: 4;
        grid-column-end: span 6;
      }
    }
    .two-column {
      grid-column-end: span 6;
    }
    .three-column {
      grid-column-end: span 4;
    }
    .four-column {
      grid-column-end: span 3;
    }
  `};

  ${media.tablet`
    gap: var(--large-space);
    margin: var(--large-space) var(--large-space) calc(var(--large-space) * 2);
  `};
`
export const ProjectWrapper = styled(motion.div)`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  overflow: scroll;

  color: var(--colour-text);
  background: var(--colour-bg);
`

export const TitleWrapper = styled.div`
  position: sticky;
  top: 9.5rem;
  z-index: 2;
  margin: var(--small-space);
  pointer-events: none;

  ${media.smallTablet`
    margin: var(--large-space) var(--small-space);
    line-height: 1.2;
  `}
  ${media.tablet`
    margin: var(--large-space);
  `};
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--medium-type);
  margin: 0 0 6rem;
  & p {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  ${media.smallTablet`
    grid-column-start: 1;
    grid-column-end: span 8;
  `};
  ${media.largeDesktop`
    grid-column-start: 5;
    grid-column-end: span 4;
  `};
`

export const MetaWrapper = styled(DescriptionWrapper)`
  margin: calc(var(--large-space) * 2) 0;

  ${media.tablet`
    margin-bottom: 0;
  `};
`

export const Title = styled.div`
  font-family: var(--font-medium);
  font-size: var(--medium-type);
  pointer-events: none;
  margin-right: 33%;

  ${media.smallTablet`
    font-size: var(--x-large-type);
    margin-right: auto;
  `}
`

export const Meta = styled.div`
  font-size: var(--small-type);
  font-family: var(--font-medium);
  line-height: 1.4;
  pointer-events: none;
  position: absolute;
  top: 0.5em;
  right: 0;

  ${media.smallTablet`
    font-size: var(--medium-type);
    right: .3em;
  `}
`

export const Italic = styled.span`
  font-family: var(--font-italic);
`

export const ItalicMedium = styled.span`
  font-family: var(--font-medium-italic);
`
