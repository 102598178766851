import React from "react"
import styled from "styled-components"
import { media } from "../layout/mediaQueries"
import { useStaticQuery, graphql } from "gatsby"
import InstaIcon from "../images/insta.inline.svg"

const About = () => {
  const data = useStaticQuery(graphql`
    query SiteSettings {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        instagram
        address
        aboutText
        email
        siteName
        phone
      }
    }
  `)

  const year = getYear()

  const {
    aboutText,
    address,
    email,
    phone,
    instagram,
    siteName,
  } = data.sanitySiteSettings

  return (
    <Wrapper>
      <InnerWrapper>
        <AboutText>{aboutText}</AboutText>
        <ContactDetailsWrapper>
          <ContactText style={{ width: "var(--credit-width)" }}>
            <p>{address}</p>
          </ContactText>
          <ContactText>
            <p>
              <a href={`mailto:${email}`}>{email}</a>
              <br />
              {phone}
            </p>
            <InstaLink
              href={`https://www.instagram.com/${instagram}`}
              rel="external nofollow noreferrer"
              target="_blank"
              className="instagram"
            >
              <InstaIcon />
              {instagram}
            </InstaLink>
          </ContactText>
        </ContactDetailsWrapper>
        <Copyright>
          <div>
            &copy; {siteName} {year}
          </div>
          <div style={{ width: "var(--credit-width)" }}>
            <a
              href={`https://www.jensjanson.com/`}
              rel="external nofollow noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              Site by Jens Janson Ltd.
            </a>
          </div>
        </Copyright>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: calc(100vh - var(--header-height));
  background-color: var(--colour-yellow);
  overflow: hidden;
  display: flex;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--large-space);

  ${media.smallTablet`
    justify-content: center;
  `}
`

const AboutText = styled.div`
  font-family: var(--font-medium);
  font-size: var(--large-type);
  line-height: 1.1;
  max-width: 67rem;
  white-space: pre-wrap;
  text-align: left;
  flex-grow: 1;
  margin-bottom: 3rem;

  ${media.smallTablet`
    font-size: var(--x-large-type);
    max-width: 70vw;
    margin: 5vh auto;
    flex-grow: 0;
  `}
  ${media.desktop`
    max-width: 100rem;
    margin: 16vh auto var(--large-space);
  `}
`

const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.smallTablet`
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: var(--large-space);
  `}
`

const ContactText = styled.div`
  font-family: var(--font-regular);
  font-size: var(--medium-type);
  text-align: left;
  line-height: 1.2;
  white-space: pre-wrap;

  ${media.smallTablet`
    font-size: var(--medium-type);
  `}
`

const InstaLink = styled.a`
  display: flex;
  text-decoration: none;
  height: 2.4rem;
  width: 13.5rem;

  svg {
    display: inline-block;
    width: 2.4rem;
    margin-right: 0.4rem;
  }
`

const Copyright = styled.div`
  padding-top: var(--large-space);
  font-size: var(--small-type);
  color: var(--colour-text);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const getYear = () => {
  const date = new Date()
  return date.getFullYear()
}

export default About
