import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { sample as _sample } from "lodash"

import { store } from "../store"

const InitializeState = React.memo(() => {
  const isStateInitialized = useSelector(state => state.isInitialized)
  const {
    allSanityProject: { nodes: allProjects },
    sanityFeaturedProjects: { projects: allFeaturedProjects },
  } = useStaticQuery(query)

  useEffect(() => {
    if (!isStateInitialized) {
      const featuredProjectId = _sample(allFeaturedProjects)
      const projects = allProjects.filter(
        ({ _id }) => _id !== featuredProjectId._id
      )
      const featuredProject = allProjects.filter(
        ({ _id }) => _id === featuredProjectId._id
      )[0]
      store.dispatch(function init(dispatch) {
        dispatch({ type: "setProjects", payload: projects })
        dispatch({ type: "setFeaturedProject", payload: featuredProject })
        dispatch({ type: "isInitialized", payload: true })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjects, allFeaturedProjects])

  return null
})

export const query = graphql`
  query ProjectQuery {
    allSanityProject(sort: { order: DESC, fields: projectDate___sortYear }) {
      nodes {
        ...Project
      }
    }
    sanityFeaturedProjects(_id: { eq: "featuredProjects" }) {
      projects {
        _id
      }
    }
  }
`

export default InitializeState
