import React from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { document } from "browser-monads"

import { media } from "src/layout/mediaQueries"
import PunktLogo from "src/images/punkt-logo.inline.svg"

const PunktHeader = ({ location }) => {
  const isModalMode = useSelector(state => state.isModalMode)
  const isHome = location?.pathname === "/"

  const scrollToAbout = () => {
    const aboutEl = document.getElementById("about")
    aboutEl.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Header>
      {!isModalMode && (
        <CircleWrapper>
          <Button
            onClick={e => {
              e.preventDefault()
              isHome ? scrollToAbout() : navigate("/")
            }}
          >
            <Circle>
              <AnimatePresence>{!isHome && <Cross />}</AnimatePresence>
            </Circle>
          </Button>
        </CircleWrapper>
      )}
      <LogoWrapper>
        <PunktLogo />
      </LogoWrapper>
    </Header>
  )
}

const Header = styled.header`
  position: sticky;
  width: 100vw;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  padding: var(--small-space);
  margin-bottom: calc(-1 * var(--header-height));
  ${media.tablet`
    padding: var(--large-space);
  `}
`
// calc(var(--small-space) + var(--offset))
// calc(var(--large-space) + var(--offset))
const CircleWrapper = styled.div`
  margin-right: var(--large-space);
  margin-left: 0.3rem;
`

const Button = styled.button`
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
`

const Circle = styled.div`
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  background-color: var(--colour-text);
  border-radius: 50%;
  transition: background 0.3s ease;
`

const Cross = () => (
  <CrossContainer
    animate={{ opacity: 1, transform: "scale(1)" }}
    initial={{ opacity: 0, transform: "scale(0.5)" }}
    exit={{ opacity: 0, transform: "scale(0)" }}
  >
    <CrossPartOne /> <CrossPartTwo />
  </CrossContainer>
)

const CrossContainer = styled(motion.div)`
  position: relative;
  width: var(--circle-diameter);
  height: var(--circle-diameter);
`

const CrossPartOne = styled.div`
  width: 30px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: var(--colour-bg);
  transition: background 0.3s ease;
`

const CrossPartTwo = styled(CrossPartOne)`
  transform: translate(-50%, -50%) rotate(-45deg);
`

const LogoWrapper = styled.div`
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 0.3rem;
  width: 11rem;
  svg {
    fill: var(--colour-text);
    transition: fill 0.3s ease;
  }
`

export default PunktHeader
