import React from "react"
import styled from "styled-components"

import FeaturedProject from "src/components/FeaturedProject"
import ProjectList from "src/components/ProjectList"

const Home = () => {
  return (
    <>
      <FeaturedProject />
      <ProjectList />
      <YellowSpacer id="about" />
    </>
  )
}

const YellowSpacer = styled.div`
  background: var(--colour-yellow);
  height: var(--header-height);
`

export default Home
