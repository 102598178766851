import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

const initialState = {
  projects: [],
  featuredProject: {},
  isModalMode: false,
  isInitialized: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "setProjects":
      return { ...state, projects: action.payload }
    case "setFeaturedProject":
      return { ...state, featuredProject: action.payload }
    case "setModalMode":
      return { ...state, isModalMode: action.payload }
    case "isInitialized":
      return { ...state, isInitialized: action.payload }
    default:
      return state
  }
}

export const getProjectFromSlug =
  slug =>
  ({ projects, featuredProject }) => {
    return [...projects, featuredProject].filter(
      project => project?.slug?.current === slug
    )[0]
  }

export const store = createStore(reducer, applyMiddleware(thunk))
