const useProjectData = project => ({
  slug: `${project?.slug?.current}`,
  url: `/project/${project?.slug?.current}`,
  alt: `${project?.artist?.name} — ${project?.title}`,
  artist: project?.artist?.name,
  title: project?.title,
  hero: project?.heroImage?.asset,
  id: project?._id,
  color: project?.heroImage?.asset?.metadata?.palette?.dominant?.background,
  bgColor: rgbToString(project?.colours?.background),
  textColor: rgbToString(project?.colours?.text),
})

const rgbToString = color => {
  if (!color) return
  const { r, g, b } = color?.rgb
  return `${r}, ${g}, ${b}`
}

export default useProjectData
